export default {
  appName: 'LVLUP',
  appDesc: 'LVLUP Fitness App',
  appUrl: 'https://app.levelupfitness.ch/',
  backendName: 'LVLUP Backend',
  backendDesc: 'LVLUP Fitness Backend',
  backendUrl: 'https://backend.levelupfitness.ch/',
  firebaseConfig: {
    apiKey: 'AIzaSyCoc3T6yiqWTgEQBOZoKOUKCf6cLQYIhZ0',
    authDomain: 'trainup-levelup.firebaseapp.com',
    projectId: 'trainup-levelup',
    storageBucket: 'trainup-levelup.appspot.com',
    messagingSenderId: '735264156203',
    appId: '1:735264156203:web:78bf6fa855d0581f63586e',
  },
  firebaseRegion: 'europe-west6',
  theme: {
    defaultTheme: 'dark',
    themes: {
      dark: {
        dark: true,
        colors: {
          primary: '#f44336',
          secondary: '#4fc3f7'
        },
        bgImg: 'bg_dark.jpg'
      },
      light: {
        dark: false,
        colors: {
          primary: '#f44336',
          secondary: '#4fc3f7'
        },
        bgImg: 'bg_light.webp'
      }
    }
  }
}
