import { createVuetify } from 'vuetify'
import { VDateInput } from 'vuetify/labs/VDateInput'
import { de } from 'vuetify/locale'

import config from '@/config.js'

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

export default createVuetify({
  components: {
    VDateInput,
  },
  locale: {
    locale: 'de',
    messages: { de }
  },
  theme: config.theme
})
