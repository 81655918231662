import { initializeApp } from 'firebase/app'
import { getAuth, connectAuthEmulator, onAuthStateChanged, signOut, setPersistence, browserSessionPersistence, signInWithCustomToken } from 'firebase/auth'
import { getFirestore, connectFirestoreEmulator, doc, onSnapshot } from 'firebase/firestore'
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getStorage, connectStorageEmulator } from 'firebase/storage'
import config from '@/config.js'

const USE_EMULATOR_SUITE = config.firebaseConfig.projectId.substring(0, 5) === 'demo-'

const app = initializeApp(config.firebaseConfig)

const auth = getAuth(app)
if (USE_EMULATOR_SUITE) {
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
}

const db = getFirestore(app)
if (USE_EMULATOR_SUITE) {
  connectFirestoreEmulator(db, 'localhost', 8080)
}

const fn = getFunctions(app, config.firebaseRegion)
if (USE_EMULATOR_SUITE) {
  connectFunctionsEmulator(fn, 'localhost', 5001)
}

const fs = getStorage(app)
if (USE_EMULATOR_SUITE) {
  connectStorageEmulator(fs, 'localhost', 9199)
}

const user = ref(null)
const userId = computed(() => user.value?.uid || user.value)

onAuthStateChanged(auth, async (authUser) => {
  if (authUser) {
    // auth
    const userAuth = { uid: authUser.uid, email: authUser.email }
    // data
    onSnapshot(doc(db, 'users/' + authUser.uid), snap => {
      const userData = snap.data()
      user.value = { ...userAuth, ...userData }
    })
  } else {
    user.value = false
  }
})

async function signInWithTkn(tkn) {
  await signOut(auth)
  await setPersistence(auth, browserSessionPersistence)
  await signInWithCustomToken(auth, tkn)
}

const urlParams = new URLSearchParams(window.location.search)
const tkn = urlParams.get('tkn')
if (tkn) {
  signInWithTkn(tkn)
  window.history.replaceState(null, '', window.location.href.substring(0, window.location.href.indexOf('?')))
}

export {
  app,
  auth,
  db,
  fn,
  fs,
  user,
  userId
}