<template>
  <v-snackbar v-model="show" timeout="-1" location="top">
    {{ $t('update_available') }}
    <template #actions>
      <v-btn
        color="primary"
        :loading="updating"
        @click="update"
      >
        {{ $t('update_install') }}
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { useRegisterSW } from 'virtual:pwa-register/vue'

const { needRefresh, updateServiceWorker } = useRegisterSW()

const show = ref(false)
const updating = ref(false)

watch(needRefresh, needRefresh => { show.value = !!needRefresh })

function update() {
  updating.value = true
  updateServiceWorker()
}
</script>