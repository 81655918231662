const snack = ref({ show: false })

export function useSnack() {
  function showSnack(text, color = 'secondary') {
    snack.value = {
      text,
      color,
      show: true
    }
  }

  return {
    snack,
    showSnack
  }
}