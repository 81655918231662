import '@/firebase'
import { createApp } from 'vue'
import App from './App.vue'
import filters from './plugins/filters'
import i18n from './plugins/i18n'
import router from './plugins/router'
import vuetify from './plugins/vuetify'
import { useSettings } from '@/composables/settings.js'
import { loadFonts } from './plugins/webfontloader'
import './style.css'

loadFonts()

const { loadSettings } = useSettings()

loadSettings()
  .then(settings => {
    createApp(App)
      .use(filters)
      .use(i18n)
      .use(router)
      .use(vuetify)
      .provide('settings', settings)
      .mount('#app')
  })
