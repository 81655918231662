import { createI18n } from 'vue-i18n'
import sharedLabels from '@bulp/trainup-shared/labels.js'
import labels from '@/labels.js'

const messages = {}
Object.keys(sharedLabels).forEach(lang => {
  messages[lang] = {
    ...sharedLabels[lang],
    ...labels[lang]
  }
})

export default createI18n({
  legacy: false, // composition api
  locale: 'de', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages
})
