import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import 'dayjs/locale/de'
import { useI18n } from 'vue-i18n'

dayjs.extend(updateLocale)

dayjs.locale('de')
dayjs.updateLocale('de', {
  weekStart: 1, // week starts with monday
  yearStart: 4 // first week of year is the week that contains the 4th jan (In Europe, the first calendar week of the year is the week that contains four days of the new year. Source: https://www.calendar-week.org/)
})

// const DATE_FORMAT_ISO = 'YYYY-MM-DD'
const DATE_FORMAT = 'DD.MM.YYYY'
const TIME_FORMAT = 'HH:mm'
const DATEDAY_FORMAT = 'dd, DD.MMM'


// returns date obj from firebase timestamp or ymd(hi) string
function parseDate(value) {
  return value.toDate ? value.toDate() : (value + (value.length === 10 ? ' 00:00' : ''))
}


const FILTERS = {
  date (value) {
    if (!value) return ''
    return dayjs(parseDate(value)).format(DATE_FORMAT)
  },
  datetime (value) {
    if (!value) return ''
    return dayjs(parseDate(value)).format(DATE_FORMAT + ' - ' + TIME_FORMAT)
  },
  dateday (value) {
    if (!value) return ''
    return dayjs(parseDate(value)).format(DATEDAY_FORMAT).slice(0, -1)
  },
  
  price (value) {
    return value + '.-' 
  },

  yesno (value) {
    const { t } = useI18n()
    return t(value ? 'yes' : 'no')
  },

  bool (value) {
    return value ? '✔️' : '❌'
  },

  tristate (value) {
    return value ? '✔️' : (value === false ? '❌' : '✖️')
  }
}

export default {
  install: (app) => {
    app.config.globalProperties.$filters = FILTERS
  }
}
