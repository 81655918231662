import { db } from '@/firebase'
import { doc, getDoc } from 'firebase/firestore'

export function useSettings() {
  async function loadSettings() {
    return (await getDoc(doc(db, 'data/settings'))).data()
  }

  return {
    loadSettings
  }
}
